import React from "react"
import { graphql } from "gatsby"

import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = (props) => (
  <Layout>
    <SEO title="About | Constanzia Yurashko" />

    <section className="about-section">
      <div className="about-section__picture">
        <Img fluid={props.data.about_picture.childImageSharp.fluid} />
      </div>
      <div className="about-section__description">
        <p>
          With a sustainable ethos and totally committed to domestic production, Constanzia Yurashko is a high-end androgynous brand founded by a designer with the same name.
        </p>
        <p>
          Playing with historical references and gender divides, Constanzia’s designs are all about storytelling and autobiographical narrative communication with the customers. Distinctive strong tailored style, structured silhouettes, contrasting shapes and patterns, the crafting timeless pieces are made to last and be worn on any occasion. From strong structured silhouettes and tailored pieces such as coats and jackets looking great on both men and women, to long skirts, chic blouses and oversized with explosions of volume, texture and drama dresses, that will fit most of the body types and shapes.
        </p>
        <p>
          “What I do is a total self expression, a desire to communicate a message, a voice which I use according to the change in time of my mental and physical condition. Afterwards, I “drip” in each article of clothing the state of mind and the emotions I live everyday. The final garment is thought through down to every detail and always has a story behind”.
        </p>
      </div>
    </section>
  </Layout>
)

export default AboutPage

export const fluidImageAbout = graphql`
fragment fluidImageAbout on File {
  childImageSharp {
    fluid(maxWidth: 400) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    about_picture: file(relativePath: { eq: "about/about_picture2.jpg" }) {
      ...fluidImageAbout
    }
  }
`